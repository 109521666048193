"use strict";

import { sortBy, isEqual, cloneDeep } from "lodash";

class ManageJob {
  message =
    "This job will repeat {TYPE} starting on {START_DATE_TIME} and repeat {OCCURRENCE} time(s) until {END_DATE_TIME}";

  constructor(
    startDate,
    occurrences,
    endDate = null,
    endMode = 2,
    pattern = null,
    weekDay = null,
    weekCount = null,
    secondWeekDay = null,
    secondWeekCount = null
  ) {
    try {
      this.startDate = new Date(startDate);
      this.occurrences = occurrences > 0 ? occurrences : 1;
      this.endMode = endMode;
      this.endDate = endMode == 3 && endDate ? new Date(endDate) : null;
      this.pattern = pattern ? parseInt(pattern, 10) : 1;
      this.weekDay = weekDay ? parseInt(weekDay, 10) - 1 : -1;
      this.weekCount = weekCount ? parseInt(weekCount, 10) - 1 : -1;
      this.secondWeekDay = secondWeekDay ? parseInt(secondWeekDay, 10) - 1 : -1;
      this.secondWeekCount = secondWeekCount
        ? parseInt(secondWeekCount, 10) - 1
        : -1;
    } catch (error) {
      console.log(error, "ManageJob::constructor::error");
    }
  }

  generateDailySchedule() {
    try {
      const currentDate = this.startDate;
      const schedule = [];
      let counter = 0;

      if (!this.occurrences && !this.endDate) {
        return schedule;
      }

      if (this.endMode == 2 && typeof this.occurrences === "number") {
        for (let i = 0; i < this.occurrences; i++) {
          schedule.push(this.formatDateTime(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }
      } else if (this.endMode == 3 && this.endDate instanceof Date) {
        while (currentDate < this.endDate) {
          schedule.push(this.formatDateTime(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
          counter++;

          if (counter > 500) {
            break;
          }
        }
      }

      return schedule;
    } catch (error) {
      console.log(error, "ManageJob::generateDailySchedule::error");
    }
  }

  generateWeeklySchedule(interval, selectedDays = []) {
    try {
      const currentDate = this.startDate;
      const schedule = [];
      let counter = 0;
      let breakCounter = 0;
      let breakCounter2 = 0;

      if (!this.occurrences && !this.endDate) {
        return schedule;
      }

      while (
        counter < this.occurrences &&
        (this.endDate === null || currentDate <= this.endDate)
      ) {
        const dayOfWeek = currentDate.getDay() + 1;

        if (selectedDays.includes(dayOfWeek)) {
          schedule.push(this.formatDateTime(currentDate));
          counter++;
        }

        currentDate.setDate(currentDate.getDate() + 1);

        while (
          !selectedDays.includes(currentDate.getDay() + 1) &&
          counter < this.occurrences &&
          (this.endDate === null || currentDate <= this.endDate)
        ) {
          currentDate.setDate(currentDate.getDate() + 1);
          breakCounter2++;

          if (breakCounter2 > 500) {
            break;
          }
        }

        const daysToAdd = 7 * (interval - 1);
        currentDate.setDate(currentDate.getDate() + daysToAdd);
        breakCounter++;

        if (breakCounter > 500) {
          break;
        }
      }

      return schedule;
    } catch (error) {
      console.log(error, "ManageJob::generateWeeklySchedule::error");
    }
  }

  generateMonthlySchedule(interval) {
    try {
      const schedule = [];
      let currentDate = cloneDeep(this.startDate);
      let breakCounter = 0;

      if (!this.occurrences && !this.endDate) {
        return schedule;
      }

      if (this.endMode == 2 && typeof this.occurrences === "number") {
        if (this.pattern == 2) {
          currentDate.setDate(1);
        }

        if (currentDate < this.startDate) {
          currentDate = cloneDeep(this.startDate);
        }

        for (let i = 0; i < this.occurrences; i++) {
          if (this.pattern == 2 && interval == 12 && i > 0) {
            currentDate.setMonth(0);
            currentDate.setDate(1);
          }

          const date = this.getWeekDay(
            currentDate,
            this.weekDay,
            this.weekCount
          );

          const formattedDate = this.formatDateTime(date);

          if (!schedule.includes(formattedDate)) {
            schedule.push(formattedDate);
          } else {
            i--;
          }

          currentDate.setMonth(currentDate.getMonth() + interval);
        }
      } else if (this.endMode == 3 && this.endDate instanceof Date) {
        while (currentDate <= this.endDate) {
          if (this.pattern == 2 && interval == 12 && breakCounter > 0) {
            currentDate.setMonth(0);
            currentDate.setDate(1);
          }

          const date = this.getWeekDay(
            currentDate,
            this.weekDay,
            this.weekCount
          );

          const formattedDate = this.formatDateTime(date);

          if (!schedule.includes(formattedDate)) {
            schedule.push(formattedDate);
          }

          currentDate.setMonth(currentDate.getMonth() + interval);
          breakCounter++;

          if (breakCounter > 500) {
            break;
          }
        }
      }

      return schedule;
    } catch (error) {
      console.log(error, "ManageJob::generateMonthlySchedule::error");
    }
  }

  generateTwiceAMonthlySchedule() {
    try {
      const schedule = [];
      const currentDate = cloneDeep(this.startDate);
      const occurrence = cloneDeep(this.occurrences);
      const endDate = cloneDeep(this.endDate);
      let breakCounter = 0;

      if (this.endMode == 2 && typeof occurrence === "number") {
        // Calculate schedule based on the number of occurrences
        for (let i = 0; i < occurrence; i++) {
          if (this.pattern == 2) {
            const dateOne = this.getWeekDay(
              currentDate,
              this.weekDay,
              this.weekCount
            );
            const formattedDateOne = this.formatDateTime(dateOne);
            if (!schedule.includes(formattedDateOne)) {
              schedule.push(formattedDateOne);
            }

            currentDate.setDate(currentDate.getDate() + 15);

            const dateTwo = this.getWeekDay(
              currentDate,
              this.secondWeekDay,
              this.secondWeekCount
            );

            const formattedDateTwo = this.formatDateTime(dateTwo);
            if (!schedule.includes(formattedDateTwo)) {
              schedule.push(formattedDateTwo);
            }

            currentDate.setMonth(currentDate.getMonth() + 1);
            currentDate.setDate(1);
          } else {
            const dateOne = cloneDeep(currentDate);
            const formattedDateOne = this.formatDateTime(dateOne);
            if (!schedule.includes(formattedDateOne)) {
              schedule.push(formattedDateOne);
            }

            currentDate.setDate(currentDate.getDate() + 15);

            const dateTwo = cloneDeep(currentDate);
            const formattedDateTwo = this.formatDateTime(dateTwo);
            if (!schedule.includes(formattedDateTwo)) {
              schedule.push(formattedDateTwo);
            }

            currentDate.setMonth(currentDate.getMonth() + 1);
            currentDate.setDate(1);
          }
        }

        return schedule.slice(0, occurrence);
      } else if (this.endMode == 3 && endDate instanceof Date) {
        // Calculate schedule based on the end date
        while (currentDate < endDate) {
          if (currentDate > endDate) break;

          if (this.pattern == 2) {
            const dateOne = this.getWeekDay(
              currentDate,
              this.weekDay,
              this.weekCount
            );

            const formattedDateOne = this.formatDateTime(dateOne);

            if (!schedule.includes(formattedDateOne)) {
              schedule.push(formattedDateOne);
            }

            currentDate.setDate(currentDate.getDate() + 15);

            const dateTwo = this.getWeekDay(
              currentDate,
              this.secondWeekDay,
              this.secondWeekCount
            );

            if (dateTwo > endDate) {
              break;
            }

            const formattedDateTwo = this.formatDateTime(dateTwo);

            if (!schedule.includes(formattedDateTwo)) {
              schedule.push(formattedDateTwo);
            }

            currentDate.setMonth(currentDate.getMonth() + 1);
            currentDate.setDate(1);
          } else {
            const dateOne = cloneDeep(currentDate);
            const formattedDateOne = this.formatDateTime(dateOne);
            if (!schedule.includes(formattedDateOne)) {
              schedule.push(formattedDateOne);
            }

            currentDate.setDate(currentDate.getDate() + 15);

            const dateTwo = cloneDeep(currentDate);

            if (dateTwo > endDate) {
              break;
            }

            const formattedDateTwo = this.formatDateTime(dateTwo);

            if (!schedule.includes(formattedDateTwo)) {
              schedule.push(formattedDateTwo);
            }

            currentDate.setMonth(currentDate.getMonth() + 1);
            currentDate.setDate(1);
          }

          breakCounter++;

          if (breakCounter > 500) {
            break;
          }
        }
      }

      return schedule;

      /*// Create an empty object to store unique dates
      const uniqueDatesObj = {};

      // Iterate through the array and store unique dates as keys in the object
      schedule.forEach((dateTimeString) => {
        const date = dateTimeString.split(" ")[0];
        if (!uniqueDatesObj[date]) {
          uniqueDatesObj[date] = dateTimeString;
        }
      });

      // Extract unique date-time strings from the object
      const uniqueDateTimeArray = Object.values(uniqueDatesObj);

      return uniqueDateTimeArray;*/
    } catch (error) {
      console.log(error, "ManageJob::generateTwiceAMonthlySchedule::error");
    }
  }

  combineDate(scheduleDate, endTime) {
    try {
      const schedule = [];

      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      const [hour, minute, second] = endTime.split(":");

      for (let i = 0; i < scheduleDate.length; i++) {
        const startDateInstance = new Date(scheduleDate[i]);
        const endDateInstance = new Date(scheduleDate[i]);

        endDateInstance.setHours(hour);
        endDateInstance.setMinutes(minute);
        endDateInstance.setSeconds(second);

        schedule.push({
          daysOfWeek: daysOfWeek[startDateInstance.getDay()],
          startDateTime: this.formatDateTime(startDateInstance),
          endDateTime: this.formatDateTime(endDateInstance),
          startDate: this.formatDate(startDateInstance),
          endDate: this.formatDate(endDateInstance),
          startTime: this.formatTime(startDateInstance),
          endTime: this.formatTime(endDateInstance),
          status: 1,
          isEdited: 0,
          isDisabled: 0,
          isPrimary: i == 0 ? 1 : 0,
        });
      }

      return schedule;
    } catch (error) {
      console.log(error, "ManageJob::combineDate::error");
    }
  }

  formatDateTime(datetime) {
    try {
      const year = datetime.getFullYear();
      const month = String(datetime.getMonth() + 1).padStart(2, "0");
      const day = String(datetime.getDate()).padStart(2, "0");
      const hours = String(datetime.getHours()).padStart(2, "0");
      const minutes = String(datetime.getMinutes()).padStart(2, "0");
      const seconds = String(datetime.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    } catch (error) {
      console.log(error, "ManageJob::formatDateTime::error");
    }
  }

  formatDate(date) {
    try {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    } catch (error) {
      console.log(error, "ManageJob::formatDate::error");
    }
  }

  formatTime(time) {
    try {
      return time.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    } catch (error) {
      console.log(error, "ManageJob::formatTime::error");
    }
  }

  scheduleCompare(prevObject, newObject) {
    try {
      return prevObject?.ticket?.schedule_message == newObject?.message;
    } catch (error) {
      console.log(error, "ManageJob::scheduleCompare::error");
    }
  }

  teamCompare(prevObject, newObject) {
    try {
      const prevTeam = prevObject?.team?.map((row) => row.engineer_id);
      return isEqual(sortBy(prevTeam), sortBy(newObject));
    } catch (error) {
      console.log(error, "ManageJob::teamCompare::error");
    }
  }
  visitCompare(prevObject, newObject) {
    try {
      if (
        prevObject?.title != newObject?.title ||
        prevObject?.admin_reminder != newObject?.admin_reminder ||
        prevObject?.customer_reminder != newObject?.customer_reminder ||
        prevObject?.technician_reminder != newObject?.technician_reminder
      ) {
        return false;
      }
      return true;
    } catch (error) {
      console.log(error, "ManageJob::teamCompare::error");
    }
  }

  getWeekDay(param, weekDay, weekCount) {
    try {
      const date = new Date(param);

      if (this.pattern !== 2 || weekDay < 0 || weekCount < 0) {
        return date;
      }

      const year = date.getFullYear();
      const month = date.getMonth();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      const days = [];

      const lastDayOfMonth = new Date(year, month + 1, 0);

      for (let day = 1; day <= lastDayOfMonth.getDate(); day++) {
        const currentDate = new Date(year, month, day, hours, minutes, seconds);
        if (currentDate.getDay() === weekCount) {
          days.push(currentDate);
        }
      }

      let result = date;

      if (weekDay === 4) {
        result = days[days.length - 1];
      }

      if (weekDay >= 0 && weekDay < days.length) {
        result = days[weekDay];
      }

      const defaultDate = new Date(this.startDate);

      if (this.isDate1GreaterThanDate2(defaultDate, result)) {
        defaultDate.setMonth(defaultDate.getMonth() + 1);
        result = this.getWeekDay(defaultDate, weekDay, weekCount);
      }

      return result;
    } catch (error) {
      console.log(error, "ManageJob::getWeekDay::error");
    }
  }

  isDate1GreaterThanDate2(date1, date2) {
    try {
      const cloneDate1 = new Date(date1);
      const cloneDate2 = new Date(date2);
      return cloneDate1.setHours(0, 0, 0, 0) > cloneDate2.setHours(0, 0, 0, 0);
    } catch (error) {
      console.log(error, "ManageJob::isDate1GreaterThanDate2::error");
    }
  }
}

export default ManageJob;
